import { doc, getDocs, getDoc, setDoc, deleteDoc, collection, query, where } from 'firebase/firestore'
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, signOut } from 'firebase/auth'
import { db, myFirebase } from '../system/firebase/index'
import { requestLogin, loginError, requestLogout, receiveLogout, logoutError, verifyRequest, setAuthUser, receiveLogin, verifySuccess } from '../system/redux/reducers/auth'
import { setPermissionsTier } from '../system/redux/reducers/user'

import { navigate } from 'gatsby'
import { requestRegister, registerSuccess, registerError, resetUsers } from '../system/redux/reducers/user'

export const getUsers = async (id, whereArray = []) => {
    if (Array.isArray(whereArray) && whereArray.length) {
        const q = query(collection(db, 'users'), where(whereArray[0], whereArray[1], whereArray[2]))
        const queryWhereSnapshot = await getDocs(q)

        return queryWhereSnapshot
    }
    const docRef = doc(db, 'users', id)
    const docSnap = await getDoc(docRef)
    return docSnap
}

export const loginUser = (dispatch, params) => {
    dispatch(requestLogin())
    const auth = getAuth(myFirebase)
    signInWithEmailAndPassword(auth, params.email, params.password)
        .then(async (data) => {
            await verifyAuth(dispatch)
            navigate(`/dashboard`)
        })
        .catch((error) => {
            // console.log(error)
            // Do something with the error if you want!
            dispatch(loginError())
        })
}

export const logoutUser = (dispatch) => {
    dispatch(requestLogout())
    const auth = getAuth(myFirebase)
    signOut(auth)
        .then(() => {
            dispatch(receiveLogout())
        })
        .catch((error) => {
            // Do something with the error if you want!
            dispatch(logoutError())
        })
}

export const verifyAuth = (dispatch) => {
    dispatch(verifyRequest())
    const auth = getAuth(myFirebase)
    onAuthStateChanged(auth, (user) => {
        if (user !== null) {
            const docRef = doc(db, 'users', user.uid)
            getDoc(docRef).then((userDoc) => {
                if (userDoc.exists()) {
                    const permissionDocRef = doc(db, 'settings', 'permissions')
                    getDoc(permissionDocRef).then((permDoc) => {
                        if (permDoc.exists()) {
                            const userData = JSON.stringify(userDoc.data())
                            const userSerialized = JSON.stringify(user)
                            const { tier } = permDoc.data()
                            dispatch(setPermissionsTier(tier))
                            dispatch(setAuthUser(userData))
                            dispatch(receiveLogin(userSerialized))
                            dispatch(verifySuccess())
                        }
                    })

                } else {
                    dispatch(loginError())
                }
            })
        } else {
            navigate(`/logout`)
        }
    })
}

export const registerUser = async (dispatch, data) => {
    dispatch(requestRegister())
    const { email, password, role, specialist, first_name, last_name } = data

    if (typeof window !== 'undefined') {
        const { firebaseFunctionCall } = await import('../system/firebase/useFunctions')
        const createUser = firebaseFunctionCall('createUser')
        createUser({ email, password, role })
            .then((uid) => {
                const docRef = doc(db, 'users', uid.data)
                getDoc(docRef).then(docSnap => {
                    const roles = {
                        superadmin: (role === 'superadmin') ? true : false,
                        admin: (role === 'superadmin' || role === 'admin') ? true : false,
                        editor: (role === 'superadmin' || role === 'admin' || role === 'editor') ? true : false,
                        viewer: true,
                    }
    
                    if (!docSnap.exists()) {
                        // doc.data() will be undefined in this case
                        // console.log('No such document!')
                        setDoc(docRef,{
                            email: email,
                            first_name,
                            last_name,
                            roles,
                            specialist: specialist,
                            uid: uid.data,
                        })
    
                        dispatch(registerSuccess())
                    } else {
                        // console.log('Error getting document:', error)
                        dispatch(registerError())
                    }
                })
                

            })
            .catch((error) => {
                const { code } = error
                const { message } = error
                const { details } = error
                console.log(error)
                // Do something else with the error if you want!
                dispatch(registerError())
            })
    }
}

export const deleteUser = async (data) => {
    if (typeof window !== 'undefined') {
        const uid = data
        const { firebaseFunctionCall } = await import('../system/firebase/useFunctions')
        const deleteUser = firebaseFunctionCall('deleteUser')
        deleteUser(uid)
            .then(() => {
                deleteDoc(doc(db, 'users', uid))
            })
            .catch((error) => {
                console.log(error)
                // Do something with the error if you want!
            })
    }
}
export const updateUser = async (uid, data) => {
    if (typeof window !== 'undefined') {
        const payload = {
            uid: uid,
            data: data
        }
        const { firebaseFunctionCall } = await import('../system/firebase/useFunctions')
        const updateUser = firebaseFunctionCall('updateUser')
        return updateUser(payload)
            .then(() => {
                console.log('User edited successfully in firestore')
            })
            .catch((error) => {
                console.log(error)
            })
    }
    return false
}
export const updateAuth = async (uid, data) => {
    if (typeof window !== 'undefined') {
        const { firebaseFunctionCall } = await import('../system/firebase/useFunctions')
        const updateAuth = firebaseFunctionCall('updateAuth')
        return updateAuth({ uid, data })
            // .then(() => {
            //     // console.log('Success')
            // })
            .catch((error) => {console.log(error);})
    }
}